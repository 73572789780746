import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card, Tabs, Tab } from 'react-bootstrap';
import "./storeOrder.css"
import Nav from "../../navBar/navBar.jsx"

const StoreOrder = () => {
  const [order, setOrder] = useState({
    nombre: '',
    apellido: '',
    direccion: '',
    ciudad: '',
    estado: '',
    codigoPostal: '',
    telefono: '',
    correoElectronico: '',
    producto: '',
    cantidad: 0,
    total: 0,
  });

  const [showOrder, setShowOrder] = useState(false);

  const handleChange = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowOrder(true);
  };

  return (
    <Container className="store-order-container">
        <Nav />
      <Row className="row">
        <Col md={12} className="col-md-8">
        <h2 className="store-order-header">Ingrese la información de la orden</h2>
          <Tabs defaultActiveKey="informacion" id="uncontrolled-tab-example">
            <Tab eventKey="informacion" title="Información de la orden">
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="nombre" className="form-group">
                  <Form.Label className="form-label">Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    name="nombre"
                    value={order.nombre}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="apellido" className="form-group">
                  <Form.Label className="form-label">Apellido</Form.Label>
                  <Form.Control
                    type="text"
                    name="apellido"
                    value={order.apellido}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="direccion" className="form-group">
                  <Form.Label className="form-label">Dirección</Form.Label>
                  <Form.Control
                    type="text"
                    name="direccion"
                    value={order.direccion}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="ciudad" className="form-group">
                  <Form.Label className="form-label">Ciudad</Form.Label>
                  <Form.Control
                    type="text"
                    name="ciudad"
                    value={order.ciudad}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="estado" className="form-group">
                  <Form.Label className="form-label">Estado</Form.Label>
                  <Form.Control
                    type="text"
                    name="estado"
                    value={order.estado}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="codigoPostal" className="form-group">
                  <Form.Label className="form-label">Código postal</Form.Label>
                  <Form.Control
                    type="text"
                    name="codigoPostal"
                    value={order.codigoPostal}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="telefono" className="form-group">
                  <Form.Label className="form-label">Teléfono</Form.Label>
                  <Form.Control
                    type="text"
                    name="telefono"
                    value={order.telefono}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="correoElectronico" className="form-group">
                  <Form.Label className="form-label">Correo electrónico</Form.Label>
                  <Form.Control
                    type="email"
                    name="correoElectronico"
                    value={order.correoElectronico}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="producto" className="form-group">
                  <Form.Label className="form-label">Producto</Form.Label>
                  <Form.Control
                    type="text"
                    name="producto"
                    value={order.producto}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Form.Group controlId="cantidad" className="form-group">
                  <Form.Label className="form-label">Cantidad</Form.Label>
                  <Form.Control
                    type="number"
                    name="cantidad"
                    value={order.cantidad}
                    onChange={handleChange}
                    className="form-control"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="btn-primary">
                  Enviar orden
                </Button>
 </Form>
              </Tab>
              <Tab eventKey="detalles" title="Detalles de la orden" disabled={!showOrder}>
                {showOrder && (
                  <Card className="card">
                    <Card.Body className="card-body">
                      <Card.Title className="card-title">Orden #{Math.floor(Math.random() * 1000)}</Card.Title>
                      <Card.Text className="order-info">
                        <strong>Nombre:</strong> {order.nombre} {order.apellido}
                      </Card.Text>
                      <Card.Text className="order-info">
                        <strong>Dirección:</strong> {order.direccion}, {order.ciudad}, {order.estado} {order.codigoPostal}
                      </Card.Text>
                      <Card.Text className="order-info">
                        <strong>Teléfono:</strong> {order.telefono}
                      </Card.Text>
                      <Card.Text className="order-info">
                        <strong>Correo electrónico:</strong> {order.correoElectronico}
                      </Card.Text>
                      <Card.Text className="order-info">
                        <strong>Producto:</strong> {order.producto}
                      </Card.Text>
                      <Card.Text className="order-info">
                        <strong>Cantidad:</strong> {order.cantidad}
                      </Card.Text>
                      <Card.Text className="total">
                        <strong>Total:</strong> ${order.cantidad * 20} {/* Suponiendo un precio de $20 por producto */}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    );
};

export default StoreOrder;