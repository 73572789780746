import React from 'react';
import { useStore } from '../store/store.jsx';

const ProductManagement = () => {
  const { products } = useStore(); // Get products from the store

  return (
    <div className='product-management-container'>
      <h1>Product Management</h1>
      <ul>
        {products.map((product, index) => (
          <li key={index}>
            <h2>{product.name}</h2>
            <p>ID: {product.id}</p>
            <p>Description: {product.description}</p>
            <p>Price: {product.price}</p>
            <p>Stock Quantity: {product.stockQuantity}</p>
            {/* Add more product details as needed */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductManagement;
