// Importe de React
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Importe de CSS
import './productOrder.css';

// Componente ProductOrder
function ProductOrder() {
  // Estado para almacenar la lista de pedidos
  const [orders, setOrders] = useState([]);

  // Función para obtener la lista de pedidos
  const getOrders = async () => {
    try {
      // Simulación de llamada a API para obtener pedidos
      const response = await fetch('https://api.example.com/orders');
      const data = await response.json();
      setOrders(data);
    } catch (error) {
      console.error(error);
    }
  };

  // Efecto para obtener la lista de pedidos al montar el componente
  useEffect(() => {
    getOrders();
  }, []);

  // Renderizado del componente
  return (
    <div className="product-order">
      <h1>Pedidos de productos</h1>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Producto</th>
            <th>Cantidad</th>
            <th>Precio</th>
            <th>Fecha de pedido</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={order.id}>
              <td>{order.id}</td>
              <td>{order.productName}</td>
              <td>{order.quantity}</td>
              <td>{order.price}</td>
              <td>{order.orderDate}</td>
              <td>
                <Link to={`/orders/${order.id}`}>Ver detalles</Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ProductOrder;