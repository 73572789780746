import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, message, Switch, Upload } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, UserOutlined, UploadOutlined } from '@ant-design/icons';
import { useStore } from '../store/store.jsx'; // Importa el store
import axios from 'axios'; // Importa axios para hacer la petición
import Navbar from '../navBar/navBar.jsx';
const UserManagement = () => {
  const { users, setUsers } = useStore(); // Obtiene la lista de usuarios y la función para actualizarla
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  //se le asigna el url al getUsersUrl
  const getUsersUrl = "https://inventory.raicotech.cl:4500/getUsers";

  //user y pwd de pruebas
  const user = "jroco";
  const pwd = "Qwerty12345#";

const fetchUsers = async () => {
  try {
    const response = await axios.get(
      getUsersUrl,
      JSON.stringify({"nick": user,"pwd": pwd
    }), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      withCredentials: false,
    });
    setUsers(response.data);
  } catch (error) {
    message.error('Failed to fetch users');
  }
};

  useEffect(() => {
    fetchUsers();
  }, []);

  // Función para editar un usuario
  const handleEdit = (record) => {
    // Aquí puedes agregar la lógica para editar un usuario
    console.log('Editar usuario:', record);
  };

  // Función para eliminar un usuario
  const handleDelete = (id) => {
    // Aquí puedes agregar la lógica para eliminar un usuario
    console.log('Eliminar usuario:', id);
  };

  // Renderiza la tabla con los datos de los usuarios
  return (
    <div>
    <div>
      <Navbar/>
    </div>
    
      <h1>User Management</h1>
      <Table
        dataSource={users}
        columns={[
          { title: 'ID', dataIndex: 'id', key: 'id' },
          { title: 'Nick', dataIndex: 'nick', key: 'nick' },
          { title: 'First Name', dataIndex: 'firstName', key: 'firstName' },
          { title: 'Last Name', dataIndex: 'lastName', key: 'lastName' },
          { title: 'Email', dataIndex: 'email', key: 'email' },
          { title: 'Phone Number', dataIndex: 'phoneNumber', key: 'phoneNumber' },
          { title: 'Business', dataIndex: 'business', key: 'business' },
          { title: 'Enterprise', dataIndex: 'enterprise', key: 'enterprise' },
          {
            title: 'Actions',
            key: 'actions',
            render: (record) => (
              <div className="action-buttons">
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEdit(record)}
                  type="ghost"
                >
                  Edit
                </Button>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={() => handleDelete(record.id)}
                  type="ghost"
                >
                  Delete
                </Button>
              </div>
            )
          }
        ]}
        rowKey="id"
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};

export default UserManagement;