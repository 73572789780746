import React, { useState, useEffect } from 'react';
import './inventoryReport.css';
import NavBar from '../navBar/navBar.jsx';
import { Table, Button, Modal, Form, Input, message, Switch, Upload } from 'antd';
import { UploadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useStore } from '../store/store.jsx';
import * as XLSX from 'xlsx';

const InventoryReport = () => {
  const { products, setProducts } = useStore();
  const [inventoryData, setInventoryData] = useState([]);
  const [totalInventoryValue, setTotalInventoryValue] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchInventoryData = async () => {
      try {
        const mockInventoryData = [
          { id: 1, product: 'Product A', quantity: 10, unitPrice: 500, totalValue: 5000 },
          { id: 2, product: 'Product B', quantity: 5, unitPrice: 200, totalValue: 1000 },
          { id: 3, product: 'Product C', quantity: 5, unitPrice: 200, totalValue: 1000 },
          // Agrega más productos aquí para probar
        ];

        setInventoryData(mockInventoryData);
        setTotalInventoryValue(mockInventoryData.reduce((sum, item) => sum + item.totalValue, 0));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching inventory data:', error);
        setLoading(false);
      }
    };

    fetchInventoryData();
  }, []);

  const renderInventoryTable = () => {
    return (
      <Table
        dataSource={inventoryData}
        columns={[
          { title: 'Product', dataIndex: 'product', key: 'product' },
          { title: 'Quantity', dataIndex: 'quantity', key: 'quantity' },
          { title: 'Unit Price', dataIndex: 'unitPrice', key: 'unitPrice' },
          { title: 'Total Value', dataIndex: 'totalValue', key: 'totalValue' },
        ]}
        rowKey="id"
        pagination={{ pageSize: 5 }}
      />
    );
  };

  const renderInventorySummary = () => {
    return (
      <div className="inventory-summary">
        <h2>Inventory Summary</h2>
        <div className="summary-metrics">
          <div className="metric">
            <span>Total Inventory Value</span>
            <strong>${totalInventoryValue.toFixed(2)}</strong>
          </div>
          <div className="metric">
            <span>Total Products</span>
            <strong>{inventoryData.length}</strong>
          </div>
        </div>
      </div>
    );
  };

  const downloadExcel = () => {
    // Crear un libro de trabajo y una hoja de trabajo
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(inventoryData);

    // Agregar la hoja de trabajo al libro de trabajo
    XLSX.utils.book_append_sheet(wb, ws, 'InventoryData');

    // Descargar el archivo Excel
    XLSX.writeFile(wb, 'Inventory_Report.xlsx');
  };

  if (loading) {
    return <div>Loading inventory data...</div>;
  }

  return (
    <div className='ResponsiveShiet'>
      <div className='Body_Content'>
        <div className='NavBar'>
          <NavBar />
        </div>
        <div className="inventory-report-container">
          <h1>Inventory Report</h1>
          {renderInventorySummary()}
          <button onClick={downloadExcel} className="btn btn-primary">
            Download Excel
          </button>
          {renderInventoryTable()}
        </div>
      </div>
    </div>
  );
};

export default InventoryReport;