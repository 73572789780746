import React, { useState } from 'react';

const StoreManagement = () => {
  const [products, setProducts] = useState([
    { id: 1, name: 'Producto 1', price: 10.99 },
    { id: 2, name: 'Producto 2', price: 5.99 },
    { id: 3, name: 'Producto 3', price: 7.99 },
  ]);

  const [newProduct, setNewProduct] = useState({
    id: '',
    name: '',
    price: '',
  });

  const [editProduct, setEditProduct] = useState(null);

  const handleAddProduct = () => {
    if (newProduct.name && newProduct.price) {
      setProducts([...products, newProduct]);
      setNewProduct({ id: '', name: '', price: '' });
    }
  };

  const handleEditProduct = (product) => {
    setEditProduct(product);
  };

  const handleUpdateProduct = () => {
    if (editProduct) {
      const updatedProducts = products.map((product) =>
        product.id === editProduct.id ? editProduct : product
      );
      setProducts(updatedProducts);
      setEditProduct(null);
    }
  };

  const handleDeleteProduct = (id) => {
    const updatedProducts = products.filter((product) => product.id !== id);
    setProducts(updatedProducts);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editProduct) {
      setEditProduct({ ...editProduct, [name]: value });
    } else {
      setNewProduct({ ...newProduct, [name]: value });
    }
  };

  return (
    <div>
      <h1>Gestión de Tienda</h1>
      <h2>Agregar Producto</h2>
      <form>
        <label>
          ID:
          <input
            type="text"
            name="id"
            value={newProduct.id}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Nombre:
          <input
            type="text"
            name="name"
            value={newProduct.name}
            onChange={handleInputChange}
          />
        </label>
        <label>
          Precio:
          <input
            type="number"
            name="price"
            value={newProduct.price}
            onChange={handleInputChange}
          />
        </label>
        <button onClick={handleAddProduct}>Agregar Producto</button>
      </form>
      <h2>Productos</h2>
      <ul>
        {products.map((product) => (
          <li key={product.id}>
            {product.name} - ${product.price}
            <button onClick={() => handleEditProduct(product)}>Editar</button>
            <button onClick={() => handleDeleteProduct(product.id)}>Eliminar</button>
          </li>
        ))}
      </ul>
      {editProduct && (
        <form>
          <label>
            ID:
            <input
              type="text"
              name="id"
              value={editProduct.id}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Nombre:
            <input
              type="text"
              name="name"
              value={editProduct.name}
              onChange={handleInputChange}
            />
          </label>
          <label>
            Precio:
            <input
              type="number"
              name="price"
              value={editProduct.price}
              onChange={handleInputChange}
            />
          </label>
          <button onClick={handleUpdateProduct}>Actualizar Producto</button>
        </form>
      )}
    </div>
  );
};

export default StoreManagement;