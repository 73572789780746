import React, { useState } from 'react';
import './orders.css';
import Nav from '../navBar/navBar.jsx';
import Header from '../dashboard/Header/Header.jsx';
import { Button, Modal, Form } from 'react-bootstrap';

function Orders() {
  const initialOrders = [
    { order: '#001', date: '2024-11-01', user: 'User1', customerName: 'John Doe', cantidad: 3, precio: '$150', estado: 'Completado', tipoPago: 'Tarjeta de Crédito', tipoOrden: 'Express', grupo: 'Grupo A' },
    // Agrega más órdenes aquí para probar
  ];

  const [orders, setOrders] = useState(initialOrders);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);  // Modal para agregar orden
  const [orderToEdit, setOrderToEdit] = useState(null);

  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOrders = orders.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(orders.length / itemsPerPage);

  // Manejar cambio de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Eliminar orden
  const handleDelete = (order) => {
    const updatedOrders = orders.filter(o => o.order !== order.order);
    setOrders(updatedOrders);
  };

  // Abrir modal de edición
  const handleEdit = (order) => {
    setOrderToEdit(order);
    setShowEditModal(true);
  };

  // Guardar cambios de edición
  const handleSaveEdit = () => {
    setOrders(orders.map(o => (o.order === orderToEdit.order ? orderToEdit : o)));
    setShowEditModal(false);
  };

  // Abrir modal de agregar orden
  const handleAddOrder = () => {
    setShowAddModal(true);
  };

  // Cerrar modal de agregar orden
  const handleCloseAddModal = () => {
    setShowAddModal(false);
  };

  // Manejar el envío del formulario para agregar una nueva orden
  const handleAddOrderSubmit = (e) => {
    e.preventDefault();
    const newOrder = {
      order: `#00${orders.length + 1}`, // Generar número de orden dinámico
      date: new Date().toLocaleDateString(),
      user: 'User2',
      customerName: e.target.customerName.value,
      cantidad: e.target.cantidad.value,
      precio: e.target.precio.value,
      estado: 'Pendiente',
      tipoPago: e.target.tipoPago.value,
      tipoOrden: e.target.tipoOrden.value,
      grupo: e.target.grupo.value
    };
    setOrders([...orders, newOrder]);
    setShowAddModal(false);
  };

  return (
    <div>
      <div className="NavBar">
        <Nav />
      </div>
      <div className='Body_Content'>
        <div className="container ">
          <div className='Tittle'><h2>Order Lists</h2></div>
          <div className="ResponsiveShiet">
            <table className="table table-striped table-hover">
              <thead className="thead-dark">
                <tr>
                  <th>Order</th>
                  <th>Date</th>
                  <th>User</th>
                  <th>Customer Name</th>
                  <th>Cuantity</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Payment Type</th>
                  <th>Payment Order</th>
                  <th>Group</th>
                  <th>Accions</th>
                </tr>
              </thead>
              <tbody>
                {currentOrders.map((order, index) => (
                  <tr key={index}>
                    <td>{order.order}</td>
                    <td>{order.date}</td>
                    <td>{order.user}</td>
                    <td>{order.customerName}</td>
                    <td>{order.cantidad}</td>
                    <td>{order.precio}</td>
                    <td>{order.estado}</td>
                    <td>{order.tipoPago}</td>
                    <td>{order.tipoOrden}</td>
                    <td>{order.grupo}</td>
                    <td>
                      <Button  size="sm" className="me-2" onClick={() => handleEdit(order)}>
                        Edit
                      </Button>
                      <Button  size="sm" onClick={() => handleDelete(order)}>
                        Erase
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Paginador */}
            <div className="pagination">
              {Array.from({ length: totalPages }, (_, i) => (
                <Button
                  key={i}
                  variant={currentPage === i + 1 ? 'primary' : 'secondary'}
                  onClick={() => handlePageChange(i + 1)}
                  className="me-1"
                >
                  {i + 1}
                </Button>
              ))}
            </div>
          </div>
          {/* Botón para agregar nueva orden */}
          <Button  onClick={handleAddOrder}>Add Orden</Button>
        </div>
      </div>

      {/* Modal de Agregar Orden */}
      <Modal show={showAddModal} onHide={handleCloseAddModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddOrderSubmit}>
            <Form.Group>
              <Form.Label>Customer Name</Form.Label>
              <Form.Control
                type="text"
                name="customerName"
                placeholder="Ingrese el nombre del cliente"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Cuantity</Form.Label>
              <Form.Control
                type="number"
                name="cantidad"
                placeholder="Ingrese la cantidad"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Price</Form.Label>
              <Form.Control
                type="text"
                name="precio"
                placeholder="Ingrese el precio"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Payment Method</Form.Label>
              <Form.Control
                type="text"
                name="tipoPago"
                placeholder="Ingrese el tipo de pago"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Order Type</Form.Label>
              <Form.Control
                type="text"
                name="tipoOrden"
                placeholder="Ingrese el tipo de orden"
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Group</Form.Label>
              <Form.Control
                type="text"
                name="grupo"
                placeholder="Ingrese el grupo"
                required
              />
            </Form.Group>

            <Button variant="primary" type="submit">Add Order</Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal de Edición */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderToEdit && (
            <Form>
              <Form.Group>
                <Form.Label>Order</Form.Label>
                <Form.Control
                  type="text"
                  value={orderToEdit.order}
                  readOnly
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  type="text"
                  value={orderToEdit.customerName}
                  onChange={(e) => setOrderToEdit({ ...orderToEdit, customerName: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Cuantity</Form.Label>
                <Form.Control
                  type="number"
                  value={orderToEdit.cantidad}
                  onChange={(e) => setOrderToEdit({ ...orderToEdit, cantidad: e.target.value })}
                />
              </Form.Group>
              {/* Agrega otros campos aquí */}
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveEdit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Orders;
