//Importe de 
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';

//Importe de css
import './App.css';

//Paginas de acceso publico
import Login from './components/login/login.jsx';
import Register from './components/register/register.jsx';
import NotFound  from './components/notfound/notfound.jsx';

//Componente NavBar
import NavBar from './components/navBar/navBar.jsx'

//Componentes generales de la Pagina
import Dashboard from './components/dashboard/dashboardApp.jsx';
import Customers from './components/customers/customers.jsx';
import Orders from './components/orders/orders.jsx';
import ProductOrder from './components/orders/productOrder/productOrder.jsx';
import StoreOrder from './components/orders/storeOrder/storeOrder.jsx';
import Report from './components/reeport/report.jsx';
import Settings from './components/settings/settings.jsx';
import ProductManagment from './components/productManagement/productManagement.jsx';
import SalesReport from './components/reeport/salesReport.jsx'
import InventoryReport from './components/reeport/inventoryReport.jsx';

//Componentes del usuario
import Config_usr from './components/configusr/config_usr.jsx';
import Perfil from './components/perfil/perfil.jsx';
import Logout from './components/logout/logout.jsx';
import Recoverpassword from './components/recovery/recoverpassword.jsx';

//Paginas de usuario por permiso  
import Admin_user from './components/perfil/admin_user/admin_user.jsx';
import Nrml_user from './components/perfil/nrml_user/nrml_user.jsx';
import UserRedirect from './components/userRedirect/userRedirect.jsx';
import UserManagement from './components/userManagement/userManagement.jsx';
import StoreManagement from './components/storeManagement/storeManagement.jsx';

//Componentes de inventario
import Product from './components/product/product.jsx';

function App() {
  return (
    <div className="App">
      <Router>
                  <Routes>
                    <Route exact path="/" element={<UserRedirect />} />
                    <Route exact path="/Admin_user" element={<Admin_user/>} /> 
                    <Route exact path="/Nrml_user" element={<Nrml_user/>} />
                    <Route exact path="/Login" element={<Login />} />
                    <Route exact path="/Recoverpassword" element={<Recoverpassword />} />
                    <Route exact path="/Register" element={<Register />} />
                    <Route exact path="/NavBar" element={<NavBar />} />
                    <Route exact path="/Dashboard" element={<Dashboard />} />
                    <Route exact path="/UserRedirect" element={<UserRedirect />} />
                    <Route exact path="/UserManagement" element={<UserManagement />} />
                    <Route exact path="/ProductManagment" element={<ProductManagment />} />
                    <Route exact path="/StoreManagment" element={<StoreManagement />} />
                    <Route exact path="/SalesReport" element={<SalesReport />} />                    
                    <Route exact path="/InventoryReport" element={<InventoryReport />} />
                    <Route exact path="/Customers" element={<Customers />} />
                    <Route exact path="/Orders" element={<Orders />} />
                    <Route exact path="/ProductOrder" element={<ProductOrder />} />
                    <Route exact path="/StoreOrder" element={<StoreOrder />} />
                    <Route exact path="/Report" element={<Report />} />
                    <Route exact path="/Settings" element={<Settings />} />
                    <Route exact path="/Config_usr" element={<Config_usr/>} />
                    <Route exact path="/Product" element={<Product/>} />
                    <Route exact path="/Perfil" element={<Perfil />} />
                    <Route exact path="/Logout" element={<Logout />} />
                    <Route path="*"element={<NotFound />} />
                  </Routes>
      </Router>
    </div>
  );
}

export default App;
