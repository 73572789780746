import React from 'react';
import { Card, Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import Nav from '../navBar/navBar.jsx'; // Asegúrate de tener el componente Nav
import ConfigUsr from '../configusr/config_usr.jsx'; // Asegúrate de tener el componente ConfigUsr
import { useStore } from '../store/store.jsx'; // Asegúrate de usar correctamente el store
import './perfil.css'

function Perfil() {
  const { currentUser } = useStore(); // Obtén el usuario actual del store

  // Verifica si currentUser es null
  if (!currentUser) {
    return <div>No se ha encontrado información del usuario. Por favor, inicie sesión.</div>;
  }

  console.log('Current User:', currentUser);

  return (
    <div className='Container-Body'>
      <Nav />
      <Container className='container-user'>
        <Row className="justify-content-md-center">
          <Col xs={16} md={12}>
            <Card>
              <Card.Header>
                <h2>Perfil de Usuario</h2>
              </Card.Header>
              <Card.Body>
                <Tabs defaultActiveKey="perfil" id="perfil-tabs" className="mb-3">
                  <Tab eventKey="perfil" title="Perfil">
                    <div className='profile-container'>
                      <img src={currentUser.profileImage} alt="Perfil" className="profile-avatar" />
                      <h2 className="profile-bio"><strong>Nick:</strong><br />{currentUser.nick}</h2>
                      <h2 className="profile-bio"><strong>Name:</strong><br />{currentUser.username} {currentUser.lastname}</h2>
                      <p className="profile-bio"><strong>Email:</strong><br />{currentUser.email}</p>
                      <p className="profile-bio"><strong>Phone Number:</strong><br />{currentUser.phonenumber}</p>
                      <p className="profile-bio"><strong>Tipo de Usuario:</strong><br />{currentUser.userType}</p>
                      <p className="profile-bio"><strong>Negocio:</strong><br />{currentUser.bussiness}</p>
                      <p className="profile-bio"><strong>Empresa:</strong><br />{currentUser.company}</p>
                    </div>
                  </Tab>
                  <Tab eventKey="configuracion" title="Configuración">
                    <ConfigUsr />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Perfil;
