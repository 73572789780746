import { create } from 'zustand';

// Datos Usuario
// # "nick"/"firstName"/"lastName"/"email"/"phoneNumber"/"business"/"enterprise"/"pwd" 
const storedUserType = JSON.parse(sessionStorage.getItem("userType")); // Asegúrate de parsear el valor
const storedUser  = JSON.parse(sessionStorage.getItem("user"));
const nick = JSON.parse(sessionStorage.getItem("nick"));
const firstName = JSON.parse(sessionStorage.getItem("firstName"));
const lastName = JSON.parse(sessionStorage.getItem("lastName"));
const email = JSON.parse(sessionStorage.getItem("email"));
const phoneNumber = JSON.parse(sessionStorage.getItem("phoneNumber"));
const business = JSON.parse(sessionStorage.getItem("business"));
const enterprise = JSON.parse(sessionStorage.getItem("enterprise"));
const pwd = JSON.parse(sessionStorage.getItem("pwd"));

export const useStore = create((set, get) => ({
  users: [],
  nicks:[],
  firstNames:[],
  lastNames:[],
  profileImage:[],
  emails:[],
  phoneNumbers:[],
  businesss:[],
  enterprises:[],
  pwds: [],
  products: [],
  customers: [],
  orders: [],
  currentUser:  storedUser ,
  userType: storedUserType || null,

  setUsers: (users) => set({ users }),
  setNick: (nick) => set({ nick }),
  setFirstName: (firstNames) => set({ firstNames }),
  setLastName: (lastNames) => set({ lastNames }),
  setEmail: (emails) => set({ emails }),
  setPhoneNumber: (phoneNumbers) => set({ phoneNumbers }),
  setBusiness: (businesss) => set({ businesss }),
  setEnterprise: (enterprises) => set({ enterprises }),
  setPwds: (pwds) => set({ pwds }),
  setProducts: (products) => set({ products }),
  setCustomers: (customers) => set({ customers }),
  setOrders: (orders) => set({ orders }),
  setCurrentUser :  (currentUser ) => set({ currentUser  }),
  setUserType: (userType) => set({ userType }),


  //Funciones para el roducto 
  // Función para agregar un nuevo producto
  addProduct: (product) => set((state) => ({
    products: [...state.products, product], // Agrega el nuevo producto a la lista
  })),

  // Función para eliminar un producto por ID
  removeProduct: (id) => set((state) => ({
    products: state.products.filter(product => product.id !== id), // Filtra el producto que se quiere eliminar
  })),

  // Función para actualizar un producto existente
  updateProduct: (updatedProduct) => set((state) => ({
    products: state.products.map(product => 
      product.id === updatedProduct.id ? updatedProduct : product // Reemplaza el producto existente con el actualizado
    ),
  })),

  // Función para obtener un producto por ID
  getProductById: (id) => {
    const product = get().products.find(product => product.id === id);
    return product || null; // Devuelve el producto encontrado o null si no existe
  },
}));