import React, { useState, useEffect } from 'react';
import './salesReport.css';
import * as XLSX from 'xlsx'; // Import the xlsx library
import NavBar from '../navBar/navBar';

function SalesReport() {
  const [salesData, setSalesData] = useState([]);
  const [totalSales, setTotalSales] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const mockSalesData = [
          { id: 1, date: '2023-06-01', product: 'Product A', quantity: 10, revenue: 500, Net: 10, Tax: 5, Total: 5500 },
          { id: 2, date: '2023-06-02', product: 'Product B', quantity: 5, revenue: 200, Net: 5, Tax: 2, Total: 2500 },
          { id: 3, date: '2023-06-03', product: 'Product C', quantity: 5, revenue: 200, Net: 5, Tax: 2, Total: 2500 },
          { id: 4, date: '2023-06-04', product: 'Product D', quantity: 5, revenue: 200, Net: 5, Tax: 2, Total: 2500 },
          { id: 5, date: '2023-06-05', product: 'Product E', quantity: 5, revenue: 200, Net: 5, Tax: 2, Total: 2500 },
          { id: 6, date: '2023-06-05', product: 'Product F', quantity: 5, revenue: 200, Net: 5, Tax: 2, Total: 2500 },
        ];

        setSalesData(mockSalesData);
        setTotalSales(mockSalesData.reduce((sum, item) => sum + item.revenue, 0));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sales data:', error);
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  const renderSalesTable = () => {
    return (
      <table className="sales-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Product</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Net</th>
            <th>Tax</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {salesData.map((sale) => (
            <tr key={sale.id}>
              <td>{sale.date}</td>
              <td>{sale.product}</td>
              <td>{sale.quantity}</td>
              <td>${sale.revenue.toFixed(0)}</td>
              <td>{sale.Net}</td>
              <td>{sale.Tax}</td>
              <td>{sale.Total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const renderSalesSummary = () => {
    return (
      <div className="sales-summary">
        <h2>Sales Summary</h2>
        <div className="summary-metrics">
          <div className="metric">
            <span>Total Sales</span>
            <strong>${totalSales.toFixed(2)}</strong>
          </div>
          <div className="metric">
            <span>Total Transactions</span>
            <strong>{salesData.length}</strong>
          </div>
        </div>
      </div>
    );
  };

  const downloadExcel = () => {
    // Create a workbook and a worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(salesData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'SalesData');

    // Download the Excel file
    XLSX.writeFile(wb, 'Sales_Report.xlsx');
  };

  if (loading) {
    return <div>Loading sales data...</div>;
  }

  return (
    <div className='ResponsiveShiet'>
      <div className='Body_Content'>
        <div className='NavBar'>
          <NavBar />
        </div>
        <div className="sales-report-container">
          <h1>Sales Report</h1>
          {renderSalesSummary()}
          <button onClick={downloadExcel} className="btn btn-primary">
            Download Excel
          </button>
          {renderSalesTable()}
        </div>
      </div>
    </div>
  );
}

export default SalesReport;