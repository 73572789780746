import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Eliminar el token y los datos del usuario
    sessionStorage.removeItem('userToken');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('userType'); // Asegúrate de eliminar el tipo de usuario también

    // Redirigir a la página de inicio de sesión
    const redirectToLogin = () => {
      // Agregar un parámetro único a la URL para evitar caché
      const uniqueUrl = `/login?ts=${new Date().getTime()}`;
      navigate(uniqueUrl);
      // Forzar la recarga de la página
      window.location.reload(true);
    };

    // Deshabilitar la caché para la página de inicio de sesión
    const disableCache = () => {
      console.log("Deshabilitando caché...");
      window.history.pushState(null, '', window.location.href);
      
      window.onpopstate = function () {
        console.log("Intento de retroceso detectado, redirigiendo...");
        redirectToLogin();
      };
    };

    disableCache();
    redirectToLogin(); // Redirigir inmediatamente al hacer logout
  }, [navigate]);

  return (
    <div>
      <h2>Logging out...</h2>
    </div>
  );
};

export default Logout;