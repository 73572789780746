import { useRef, useState, useEffect } from "react";
import "./login.css";
import { FaUser  } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import axios from "../axios/axios.jsx";
import UserRedirect from "../userRedirect/userRedirect.jsx";
import { Link } from 'react-router-dom';
import { useStore } from '../store/store.jsx'; // Importa el store


const LOGIN_URL = "/login";
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

const Login = () => {
  const userRef = useRef();
  const errRef = useRef(null);
  const { setCurrentUser  } = useStore(); // Obtén la función para actualizar el usuario en el store


  const [user, setUser ] = useState("");
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [userType, setUserType] = useState("");

  const [pwd, setPwd] = useState("");
  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);
  
  const [matchPwd, setMatchPwd] = useState("");
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  // Función para verificar el almacenamiento en sessionStorage
  const verifySessionStorage = () => {
    const userToken = sessionStorage.getItem("userToken");
    const user = JSON.parse(sessionStorage.getItem("user"));
    const userType = JSON.parse(sessionStorage.getItem("userType"));

    if (userToken) {
      console.log("Token almacenado:", userToken);
    } else {
      console.log("No se encontró el token en sessionStorage.");
    }

    if (user) {
      console.log("Usuario almacenado:", user);
    } else {
      console.log("No se encontró el usuario en sessionStorage.");
    }

    if (userType) {
      console.log("Usuario almacenado:", userType);
    } else {
      console.log("No se encontró el Tipo de usuario en sessionStorage.");
    }


  };

  useEffect(() => {
    userRef.current.focus();
    verifySessionStorage(); // Verificar al cargar el componente
  }, []);


  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setValidName(USER_REGEX.test(user));
  }, [user]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(pwd));
    setValidMatch(pwd === matchPwd);
  }, [pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const v1 = USER_REGEX.test(user);
    const v2 = PWD_REGEX.test(pwd);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ user, pwd }),
        {
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          withCredentials: false,
        }
      );

     
      const userType = response.data.userType;
      const userData = {
        nick: response.data.nick,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        email: response.data.email,
        phoneNumber: response.data.phoneNumber,
        business: response.data.business,
        enterprise: response.data.enterprise,
        profileImage: response.data.profileImage,
        userType: response.data.userType,
        
      };



      // Almacenar el token y/o datos del usuario en sessionStorage
      sessionStorage.setItem("userToken", response.data.accessToken); // Almacenar el token
      sessionStorage.setItem("currentUser ", JSON.stringify(userData)); // Almacena el usuario
      sessionStorage.setItem("user", JSON.stringify({ username: user })); // Almacenar el nombre de usuario
      sessionStorage.setItem("userType", JSON.stringify(userType)); // Asegúrate de que userType sea "Admin" o "Normal"
      setSuccess(true); // Cambia el estado de éxito a true
      setCurrentUser (userData); // Actualiza el estado en el store
      setUser ("");
      setPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No existe respuesta desde el Servidor.");
      } else if (err.response?.status === 409) {
        setErrMsg("Usuario Registrado.");
      } else {
        setErrMsg("Login Fallido.");
      }
      if (errRef.current) {
        errRef.current.focus();
      }
    }
  };

  return (
    <>
      {success ? (

        <UserRedirect /> // Muestra UserRedirect en lugar de NavBar

      ) : (
        <section>
          <div>
            <div className="wrapper">
              <form onSubmit={handleSubmit}>
                <h1>Inventory</h1>
                <div className="input-box inputLoginUsername">
                  <input
                    type="text"
                    placeholder="Usuario"
                    className="form-control"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser (e.target.value)}
                    value={user}
                    aria-invalid={validName ? "false" : "true"}
                    required
                    onFocus={() => setUserFocus(true)}
                    onBlur={() => setUserFocus(false)}
                  />
                  <FaUser  className="icon" />
                </div>
                <div className="input-box inputLoginPwd">
                  <input
                    type="password"
                    placeholder="Contraseña"
                    className="form-control"
                    autoComplete="off"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    aria-invalid={validPwd ? "false" : "true"}
                    aria-describedby="pwdnote required"
                    onFocus={() => setPwdFocus(true)}
                    onBlur={() => setPwdFocus(false)}
                  />
                  <FaLock className="icon" />
                </div>
                <button type="submit" disabled={!validName || !validPwd}>
                  Log In
                </button>
                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
                  {errMsg}
                </p>

                <p className="mt-3">
                  <Link to="/recoverpassword" className="text-white">
                    ¿Forgot Your Password?
                  </Link>
                </p>
                <p className="mt-3">
                  <Link to="/Register" className="text-white">
                    Usuario nuevo?, Registrate!
                  </Link>
                </p>
                
              </form>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Login;