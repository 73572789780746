import React from "react";
import "./navBar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { BsFillGearFill } from "react-icons/bs";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Link } from 'react-router-dom';


function NavBar() {
  // Obtener el userType del sessionStorage
  const storedUserType = JSON.parse(sessionStorage.getItem("userType"));

  return (
    <div className="sidebar d-flex flex-column justify-content-between bg-dark text-white p-4 vh-100 position-absolute top-0 start-0">
      <div>
        <Link to="/Dashboard" className="d-flex align-items-center ">
          <i className="bi bi-receipt-cutoff fs-5 me-3 text-white icon"></i>
          <span className="fs-4 text-white">Inventory </span>
        </Link>
        <hr className="text-secondary mt-2 " />
        <ul className="nav nav-pills flex-column px-0 sidebar">
        <li className="nav-item py-1 icon nav-item1">
            <Link to="/Dashboard" className="nav-link text white px-2 ">
              <i className="bi bi-speedometer me-2 fs-5 text-white"></i>
              <span className="fs-5 text-light">Dashboard</span>
            </Link>
          </li>


{/* Dropdown para Orders */}
<li className="nav-item dropdown py-1">
            <a
              className="nav-link dropdown-toggle text-white" 
              href="#" 
              id="reportDropdown" 
              role="button" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
              <i className=" text-light bi bi-table me-2 fs-5  "></i>
              <span className="fs-5 text-light">Orders</span>
            </a>
            <ul className="dropdown-menu bg-dark text-white" aria-labelledby="OrdersDropdown">
              <li>
                <Link to="/Orders" className="dropdown-item text-light">
                <i className=" text-light bi bi-table me-2 fs-5  "></i>
                  Product - Order
                </Link>
              </li>
              <li>
                <Link to="/StoreOrder" className="dropdown-item text-light">
                <i className=" text-light bi bi-table me-2 fs-5  "></i>
                  Store - Order
                </Link>
              </li>
              {/* Puedes añadir más elementos al dropdown aquí si es necesario */}
            </ul>
          </li>

          {/* Mostrar Customers y Settings solo si el userType es Admin */}
          {storedUserType === "Admin" && (
            <>
           {/* Dropdown para Report */}
          <li className="nav-item dropdown py-1">
            <a
              className="nav-link dropdown-toggle text-white" 
              href="#" 
              id="reportDropdown" 
              role="button" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
              <i className="icon text-light bi bi-people me-2 fs-5"></i>
              <span className="fs-5 text-light">Management </span>
            </a>
            <ul className="dropdown-menu bg-dark text-white" aria-labelledby="CustomersDropdown">
              <li>
                <Link to="/UserManagement" className="dropdown-item text-light">
                <i className="icon text-light bi bi-people me-2 fs-5"></i>
                  Users
                </Link>
              </li>
              <li>
                <Link to="/Customers" className="dropdown-item text-light">
                <i className="icon text-light bi bi-people me-2 fs-5"></i>
                  Customers
                </Link>
              </li>
              <li>
                <Link to="/Product" className="dropdown-item text-light">
                <i className="icon text-light bi bi-people me-2 fs-5"></i>
                  Products
                </Link>
              </li>
              {/* Puedes añadir más elementos al dropdown aquí si es necesario */}
            </ul>
          </li>
          
          <li className="nav-item dropdown">
            <a 
              className="nav-link dropdown-toggle text-white" 
              href="#" 
              id="reportDropdown" 
              role="button" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
               <BsFillGearFill className=" text-light bi me-2 fs-5" />
              <span className="fs-5 text-light">Settings </span>
            </a>
            <ul className="dropdown-menu bg-dark text-white" aria-labelledby="CustomersDropdown">
              <li>
                <Link to="/Settings" className="dropdown-item text-light">
                <BsFillGearFill className=" text-light bi me-2 fs-5" />
                  Settings
                </Link>
              </li>
              <li>
                <Link to="/Product" className="dropdown-item text-light">
                <BsFillGearFill className=" text-light bi me-2 fs-5" />
                  Products
                </Link>
              </li>
              <li>
                <Link to="/Store" className="dropdown-item text-light">
                <BsFillGearFill className=" text-light bi me-2 fs-5" />
                  Store
                </Link>
              </li>
              {/* Puedes añadir más elementos al dropdown aquí si es necesario */}
            </ul>
          </li>
            </>
          )}

          {/* Otras opciones que siempre se mostrarán */}


          {/* Dropdown para Report */}
          <li className="nav-item dropdown">
            <a 
              className="nav-link dropdown-toggle text-white" 
              href="#" 
              id="reportDropdown" 
              role="button" 
              data-bs-toggle="dropdown" 
              aria-expanded="false"
            >
              <i className="text-light bi bi-grid me-2 fs-5"></i>
              <span className="fs-5 text-light">Reports</span>
            </a>
            <ul className="dropdown-menu bg-dark text-white" aria-labelledby="reportDropdown">
              <li>
                <Link to="/SalesReport" className="dropdown-item text-light">
                <i className="text-light bi bi-grid me-2 fs-5"></i>
                  Selling Reports
                </Link>
              </li>
              <li>
                <Link to="/InventoryReport" className="dropdown-item text-light">
                <i className="text-light bi bi-grid me-2 fs-5"></i>
                  Inventory Report
                </Link>
              </li>
              {/* Puedes añadir más elementos al dropdown aquí si es necesario */}
            </ul>
          </li>
        </ul>
      </div>
      <div className="text-secondary custom-center py-3">
        <div className="User  -feel sidebar">
          <div className="dropdown">
            <button
              className="btn dropdown-toggle icon"
              type="button"
              id="dropdownMenuButton"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img
                src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img (31).webp"
                className="rounded-circle"
                height="22"
                alt="Portrait of a Woman"
                loading="lazy"
              />
            </button>

            <ul
              className="dropdown-menu p-3 mb-2 bg-dark text-white custom-left"
              aria-labelledby="dropdownMenuButton "
            >
              <li>
                <a className="dropdown-item p-3 mb-2 bg-dark text-white" href="Perfil">
                  Perfil
                </a>
              </li>
              <li className="nav-item">
                <a className="dropdown-item p-3 mb-2 bg-dark text-white" href="Logout">
                  Logout
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;